export default {
  book: 'Prenota',
  close: 'Chiudi',
  playReel: 'Play Reel',
  playVideo: 'Play Video',
  quickView: 'Quick View',
  contactUs: 'Contattaci',
  subscribe: 'Iscriviti',
  rotateDevice: 'Ruota il dispositivo',
  scrollToTop: 'Torna in cima alla pagina',
  backToHome: 'Torna in homepage',
  discover: 'Scopri',
  backToMenus: 'Torna ai menu',
}
