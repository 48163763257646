import errors from './errors.js'
import cta from './cta.js'
import menu from './menu.js'
import contacts from './contacts.js'
import footer from './footer.js'
import book from './book.js'

export default {
  errors,
  cta,
  menu,
  footer,
  contacts,
  book,
}
