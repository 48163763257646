export default {
  form: {
    apiPostinId: '2065fbc6-6643-4c57-a9ed-4cea500edcef',
    date: 'Data',
    hour: 'Ora',
    guests: 'Ospiti',
    moreThan7:
      'Più di 7 ospiti? <a href="tel:+390421350380" target="_blank" rel="noreferrer" class="link-underline-1">Contattaci</a>',
    name: 'Nome',
    surname: 'Cognome',
    email: 'Email',
    phone: 'Telefono',
    notes: 'Note',
    privacy:
      'Ai sensi e per gli effetti del Reg. UE 2016/679 - GDPR presto il consenso al trattamento dei miei dati personali come da <a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>informativa sulla privacy</span></a>*',
    privacyError: 'È necessario accettare la nostra policy sulla privacy.',
    newsletter:
      'Inviami aggiornamenti sugli eventi esclusivi, novità e promozioni Da Guido.',
    extraFieldsError: 'Inserisci i dati mancanti: { fields }',
    successPageTitle: 'Richiesta inviata con successo.',
    successPageText:
      'Siamo lieti di confermare la tua prenotazione. <br> Riceverai una conferma via email con i dettagli della tua prenotazione. Se dovessi avere domande o necessità di modificare la tua prenotazione, non esitare a contattarci. <br> A presto.',
    failurePageTitle: 'Siamo spiacenti',
    failurePageText:
      "Si sono verificati degli errori nell'invio del modulo. Vi preghiamo di riprovare più tardi.",
  },
  voucher: {
    successPageTitle: 'Ordine completato!',
    successPageText:
      'Grazie per averci scelto come regalo per le persone a cui tieni. <br> La nostra gift card è molto più di un semplice dono: è un invito a vivere momenti di gusto, relax e condivisione. <br>Riceverai presto un’email con il riepilogo del tuo ordine. <br>A seguire riceverai una nuova email con il Gift Voucher in formato PDF da consegnare ai destinatari del tuo pensiero di gusto! <br> A presto.',
    failurePageTitle: 'Siamo spiacenti',
    failurePageText:
      "Si sono verificati degli errori nell'invio del modulo. Vi preghiamo di riprovare più tardi.",
  },
}
