export default {
  main: {
    info: 'Ristorante da Guido <br>Via Roma Sinistra, 25 <br>30016 Jesolo Paese (Venice) <br>Tel. <a class="link-underline-reverse-1" href="tel:+39 0421 350380">+39 0421 350380</a> <br>VAT No. 03205420270',
    extra:
      'Closed on Mondays and Tuesdays at noon. <br>Animals are not allowed except for guide dogs accompanying <br>people with disabilities.',
    'book-online': 'Online form',
    'book-tel':
      'Tel: <a class="link-underline-reverse-1" href="tel:+39 0421 350380">+39 0421 350380</a>',
    social: 'Social',
    instagram:
      '<a class="link-underline-reverse-1" href="https://www.instagram.com/daguidojesolo/" rel="noopener" target="_blank">Instagram</a>',
    facebook:
      '<a class="link-underline-reverse-1" href="https://www.facebook.com/daguidojesolo/" rel="noopener" target="_blank">Facebook</a>',
    knowUs:
      'Let’s get to know each other: together we can go further than you think.',
    discover: 'Discover open positions to test your talent.',
    newsletter: 'Discover what’s behind the magic of Bluemotion.',
    emailField: 'Email',
    newsletterCaption:
      'Pursuant to and for the purposes of EU Reg. 2016/679 - GDPR, I consent to the processing of my personal data as per the <a href="/it/privacy-policy" class="link-underline-1">privacy policy</a>',
    newsletterError:
      'Please accept the data processing in compliance with the GDPR',
    associated: 'We are associated',
    copyright: '© {year} - Ristorante Da Guido',
    privacy: 'Privacy Policy',
    inviaRichiesta: 'Submit a request',
  },
}
