export default {
  'menu-nav': {
    menu: 'Menu',
    'piatti-e-vini': 'Piatti e vini',
    cucina: 'In cucina',
    'sala-giardino': 'In sala e in giardino',
    'oggi-ieri': 'Oggi come ieri',
    events: 'I tuoi eventi',
    contacts: 'Contatti',
    'work-with-us': 'Lavora con noi',
    back: 'Back',
    prenota: 'Prenota il tuo tavolo',
    regala: 'Regala una cena',
  },
  categories: {
    proposte: 'Le nostre proposte',
  },
  custom: {
    'euro-a-persona': 'euro a persona',
  },
  print: {
    coperto: 'Coperto e servizio',
    legenda: 'Legenda degli allergeni',
    allergeni: {
      cereali: '1 Cereali contenenti glutine',
      crostacei: '2 Crostacei e prodotti derivati',
      uova: '3 Uova e prodotti derivati',
      pesce: '4 Pesce e prodotti derivati',
      arachidi: '5 Arachidi e prodotti derivati',
      soia: '6 Soia e prodotti derivati',
      latte: '7 Latte e prodotti derivati',
      frutta: '8 Frutta a guscio',
      sedano: '9 Sedano e prodotti derivati',
      senape: '10 Senape e prodotti derivati',
      sesamo: '11 Semi di sesamo e prodotti derivati',
      anidride: '12 Anidride solforosa e solfiti',
      lupini: '13 Lupini e prodotti derivati',
      molluschi: '14 Molluschi e prodotti derivati',
    },
    'fixed-price-menu': 'Per tutto il tavolo, a persona',
  },
  cart: {
    categories: {
      'M.antipasti': 'Antipasti',
      'M.primi': 'Primi',
      'M.secondi': 'Secondi',
      'M.vegetariano': 'Piatti vegetariani',
      'M. Degustazione': 'Menu degustazione',
    },
  },
}
