export default {
  form: {
    apiPostinId: 'b94dacd9-be6b-40bd-bf16-3692cc929832',
    date: 'Date',
    hour: 'Time',
    guests: 'Guests',
    moreThan7:
      'More than 7 guests? <a href="tel:+390421350380" target="_blank" rel="noreferrer" class="link-underline-1">Contact us</a>',
    name: 'First Name',
    surname: 'Last Name',
    email: 'Email',
    phone: 'Phone',
    notes: 'Notes',
    privacy:
      'Pursuant to and for the purposes of EU Reg. 2016/679 - GDPR, I consent to the processing of my personal data as per the <a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>privacy policy</span></a>*',
    privacyError: 'You need to accept our privacy policy.',
    newsletter:
      'Send me updates about exclusive events, news, and promotions from Da Guido.',
    extraFieldsError: 'Enter missing data: { fields }',
    successPageTitle: 'Request successfully submitted.',
    successPageText:
      'We are pleased to confirm your reservation. <br>You will receive a confirmation email with the details of your booking. If you have any questions or need to modify your reservation, please don’t hesitate to contact us. <br>See you soon.',
    failurePageTitle: 'We are sorry',
    failurePageText:
      'There were errors in submitting the form. Please try again later.',
  },
  voucher: {
    successPageTitle: 'Order completed!',
    successPageText:
      'Thank you for choosing us as a gift for the people you care about. <br>Our gift card is much more than just a present: it’s an invitation to enjoy moments of flavor, relaxation, and connection. <br>You will soon receive an email with a summary of your order. <br>Following that, you’ll receive another email with the Gift Voucher in PDF format, ready to be shared with the lucky recipients of your thoughtful gift! <br>See you soon.',
    failurePageTitle: 'We are sorry',
    failurePageText:
      'There were errors in submitting the form. Please try again later.',
  },
}
