export default {
  'menu-nav': {
    menu: 'Menu',
    'piatti-e-vini': 'Dishes and wines',
    cucina: 'Kitchen',
    'sala-giardino': 'Indoors and in the garden',
    'oggi-ieri': 'Today as yesterday',
    events: 'Your events',
    contacts: 'Contacts',
    'work-with-us': 'Work with us',
    back: 'Back',
    prenota: 'Book your table',
    regala: 'Gift a dinner',
  },
  categories: {
    proposte: 'Our proposals',
  },
  custom: {
    'euro-a-persona': 'euros per person',
  },
  print: {
    coperto: 'Cover charge and service',
    legenda: 'Allergen legend',
    allergeni: {
      cereali: '1 Gluten-containing cereals',
      crostacei: '2 Crustaceans and derived products',
      uova: '3 Eggs and derived products',
      pesce: '4 Fish and derived products',
      arachidi: '5 Peanuts and derived products',
      soia: '6 Soy and derived products',
      latte: '7 Milk and derived products',
      frutta: '8 Nuts',
      sedano: '9 Celery and derived products',
      senape: '10 Mustard and derived products',
      sesamo: '11 Sesame seeds and derived products',
      anidride: '12 Sulphur dioxide and sulphites',
      lupini: '13 Lupins and derived products',
      molluschi: '14 Molluscs and derived products',
    },
    'fixed-price-menu': 'For the whole table, per person',
  },
  cart: {
    categories: {
      'M.antipasti': 'Starters',
      'M.primi': 'First courses',
      'M.secondi': 'Second courses',
      'M.vegetariano': 'Vegetarian dishes',
      'M. Degustazione': 'Tasting Menu',
    },
  },
}
