export default {
  form: {
    name: 'First Name*',
    surname: 'Last Name*',
    email: 'Email*',
    company: 'Company',
    agency: 'Agency*',
    phone: 'Phone',
    note: 'Notes',
    fileUpload: 'CV in PDF format*',
    fileUploadTxt: 'Upload your CV (max 2.5 MB)',
    fileUploadSizeError:
      'The file size is too large, please select a file with a max size of 2.5 MB.',
    message: 'Required description*',
    privacyCaption:
      'Pursuant to and for the purposes of EU Reg. 2016/679 - GDPR, I consent to the processing of my personal data as per the <a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>privacy policy</span></a>*',
    privacyError: 'You need to accept our privacy policy.',
    newsletterCaption:
      'I wish to receive updates on news and events from Da Guido',
    submitCTA: 'SUBMIT REQUEST',
    contattiCTA: 'Submit request',
    genericError: 'Field not filled in correctly',
    successPageTitle: 'Your request has been successfully submitted.',
    successPageText:
      'Thank you for your interest in our restaurant. One of our representatives will get back to you as soon as possible to provide all the necessary information. <br>See you soon.',
    failurePageTitle: 'We are sorry',
    failurePageText:
      'There were errors in submitting the form. Please try again later.',
  },
  contatti: {
    apiPostinId: '6426557b-3210-4fcd-88cc-0c02b25b6c48',
  },
  careers: {
    apiPostinId: 'b4f9896c-780e-444c-a401-968793291a20',
    formCTA: 'Submit application',
    formSubtitle: '<strong>Requirements and skills</strong>',
    formCaption:
      'If you think you are the right person for us, fill out the form and send your application.',
    otherPositionsTitle: 'Other open positions',
    postinApiId: 'e2209090-6264-4d73-803b-dd85af69e909',
  },
  seoSuccess: {
    title: 'Request sent',
  },
  seoFailure: {
    title: 'Error!',
  },
}
