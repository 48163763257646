export default {
  book: 'Reserve a table',
  close: 'Close',
  playReel: 'Play Reel',
  playVideo: 'Play Video',
  quickView: 'Quick View',
  contactUs: 'Contact Us',
  subscribe: 'Subscribe',
  rotateDevice: 'Rotate device',
  scrollToTop: 'Back to top',
  backToHome: 'Back to homepage',
  discover: 'Discover',
  backToMenus: 'Back to menus',
}
