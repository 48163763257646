export default {
  form: {
    name: 'Nome*',
    surname: 'Cognome*',
    email: 'E-mail*',
    company: 'Azienda',
    agency: 'Agenzia*',
    phone: 'Telefono',
    note: 'Note',
    fileUpload: 'CV in formato PDF*',
    fileUploadTxt: 'Carica il tuo curriculum (max 2,5 MB)',
    fileUploadSizeError:
      'La dimensione del file è troppo grande, si prega di selezionare un file di max 2.5 Mb.',
    message: 'Descrizione richiesta*',
    privacyCaption:
      'Ai sensi e per gli effetti del Reg. UE 2016/679 - GDPR presto il consenso al trattamento dei miei dati personali come da <a href="{ url }" target="_blank" rel="noreferrer" class="link-underline-1"><span>informativa sulla privacy</span></a>*',
    privacyError: 'È necessario accettare la nostra policy sulla privacy.',
    newsletterCaption:
      'Desidero ricevere aggiornamenti su novità ed eventi Da Guido',
    submitCTA: 'INVIA RICHIESTA',
    contattiCTA: 'Invia richiesta',
    genericError: 'Campo non compilato correttamente',
    successPageTitle: 'La tua richiesta è stata inviata con successo.',
    successPageText:
      'Ti ringraziamo per il tuo interesse per il nostro ristorante, un nostro responsabile ti risponderà al più presto per fornirti tutte le informazioni necessarie. <br>A presto.',
    failurePageTitle: 'Siamo spiacenti',
    failurePageText:
      "Si sono verificati degli errori nell'invio del modulo. Vi preghiamo di riprovare più tardi.",
  },
  contatti: {
    apiPostinId: 'e092d328-ac54-4ffb-9953-8db31803db44',
  },
  careers: {
    apiPostinId: 'c4d492f2-93b6-449e-85cb-f3f3f6bc76bb',
    formCTA: 'Invia candidatura',
    formSubtitle: '<strong>Requisiti e competenze</strong>',
    formCaption:
      'Se pensi di essere la persona che fa al caso nostro, compila il form e invia la tua cadidatura.',
    otherPositionsTitle: 'Altre posizioni aperte',
    postinApiId: 'e2209090-6264-4d73-803b-dd85af69e909',
  },
  seoSuccess: {
    title: 'Richiesta inviata',
  },
  seoFailure: {
    title: 'Errore!',
  },
}
