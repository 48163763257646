export default {
  main: {
    info: 'Ristorante da Guido <br>Via Roma Sinistra, 25 <br>30016 Jesolo Paese (Venezia) <br>Tel. <a class="link-underline-reverse-1" href="tel:+39 0421 350380">+39 0421 350380</a> <br>P.Iva 03205420270',
    extra:
      'Chiuso il lunedì e martedì a mezzogiorno. <br>L’accesso agli animali non è consentito <br>ad eccezione dei cani che accompagnano <br>persone con disabilità.',
    'book-online': 'Modulo online',
    'book-tel':
      'Tel: <a class="link-underline-reverse-1" href="tel:+39 0421 350380">+39 0421 350380</a>',
    social: 'Social',
    instagram:
      '<a class="link-underline-reverse-1" href="https://www.instagram.com/daguidojesolo/" rel="noopener" target="_blank">Instagram</a>',
    facebook:
      '<a class="link-underline-reverse-1" href="https://www.facebook.com/daguidojesolo/" rel="noopener" target="_blank">Facebook</a>',
    knowUs:
      'Conosciamoci: insieme possiamo arrivare più lontano di quanto immagini.',
    discover:
      'Scopri le posizioni aperte per mettere alla prova il tuo talento.',
    newsletter: 'Scopri cosa c’è dietro la magia di Bluemotion.',
    emailField: 'Email',
    newsletterCaption:
      'Ai sensi e per gli effetti del Reg. UE 2016/679 - GDPR presto il consenso al trattamento dei miei dati personali come da <a href="/it/privacy-policy" class="link-underline-1">informativa sulla privacy</a>',
    newsletterError:
      'Si prega di accettare il trattamento dei dati conforme al GDPR',
    corporate:
      '<strong>Bluemotion Srl</strong> Viale del Lavoro 14, int 34, 35020 Ponte San Nicolò, Padova P.iva 03897210286',
    associated: 'Siamo associati',
    copyright: '© {year} - Ristorante Da Guido',
    privacy: 'Privacy Policy',
    inviaRichiesta: 'Invia una richiesta',
  },
}
